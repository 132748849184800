.mobile_grid{
    grid-template-areas: 'body' 
                         'footer' !important; 
    grid-template-rows:   1fr 250px !important;
}

.menu_desktop_out{
    display:none;
    height: 0;
}

.menu_desktop_in{
    animation: menu_destkop_in 1s forwards;
}

.menu_mobile_in{
    animation: menu_mobile_in 1s forwards;
    display: block !important;
}

.menu_mobile_out{
    animation: menu_mobile_out 1s forwards !important;
}




