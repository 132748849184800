@import "../../variables";

.inputSubmitContainer,
.inputContainer {
	background: var(--light-grey);
	border-radius: 4px;
	height: 64px;
	position: relative;
	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;

	flex-direction: column;

	transition: all 0.4s;
	padding: 10px;

	top: 0;
	width: 100%;

	&:not(:first-of-type) {
		margin-top: 10px;
	}

	span.validation-icon {
		right: 1rem;
	}

	&.input-error {
		background: #f8d7da !important;
		animation: inputError 0.3s;

		.inputPlaceholder {
			color: #7f2e35 !important;
		}
		input {
			color: #7f2e35;
		}

		@keyframes inputError {
			0%,
			100% {
				transform: translateX(0%);
			}
			20% {
				transform: translateX(3%);
			}
			60% {
				transform: translateX(-3%);
			}
		}
	}

	&.input-success {
		background: #d4edda !important;
		.inputPlaceholder {
			color: green !important;
		}

		input {
			color: #265e2b;
		}
	}

	input {
		width: 100%;
		border: none;
		background: transparent;
		-moz-appearance: textfield;

		&:focus {
			outline: none;
		}
	}

	input[type="number"],
	::-webkit-outer-spin-button,
	input[type="number"],
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&.inputDisabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&:has(input:user-invalid) {
		background-color: #7f2e35 !important;
	}

	&.checkbox {
		background-color: unset;
		display: flex;
		flex-direction: row-reverse;

		justify-content: flex-end;

		margin-left: 0 !important;
		padding-left: 0 !important;

		&.active {
			label {
				color: #2aa952;
				width: 300px;
			}
		}

		input:checked {
			background-color: pink;
			color: pink !important;
		}

		input {
			width: 30px;
			justify-self: flex-start;
			background-color: unset;
		}

		label {
			width: 300px;
		}
	}
}

.inputPlaceholder {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #9b9b9b;
	width: 100%;
}

.inputSubmitContainer {
	height: 48px;
	width: 100%;
	@include border;
	background-color: $primary !important;

	position: relative;

	transition: 0.5s;
	& > .inputSubmit {
		width: 100%;
		height: 48px;
		background: none;
		border: none;
		font-weight: bold;
		font-size: 14px;
		color: #e8e9eb;
		border-radius: $border_radius;
		cursor: pointer;
		position: absolute;
		z-index: 1;
		&:disabled {
			opacity: 0;
			cursor: progress;

			& + #loadingEffectContainer {
				z-index: 2;
				width: 100%;
				border-radius: $border_radius;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;

				.loader {
					border: 5px solid #f3f3f3;
					border-top: 5px solid $grey_support_font_color;
					border-radius: 50%;
					width: 25px;
					height: 25px;
					animation: spin 2s linear infinite;
				}

				@keyframes spin {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
			}
		}
	}

	&.disabled {
		background: $grey_support_font_color !important ;
		pointer-events: none;
	}

	cursor: pointer;
}

.inputRadioContainer {
	border-radius: 4px;
	height: 64px;
	padding: 00px 15px 0px 15px;
	margin-bottom: 20px;

	label {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		color: #9b9b9b;
	}

	.inputRadioContent {
		margin-top: 0;
		background: none;
		height: 50px;
		display: flex;
		align-items: center;

		label {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			text-align: center;
			color: #9b9b9b;
			margin-right: 20px;
		}

		input {
			margin-right: 5px;
		}
	}
}

.searchbar_container {
	width: 100%;
	.searchbar {
		width: 100%;
		background-color: $grey_support;
		height: 40px;
		border-radius: 10px;

		display: grid;
		align-content: center;
		justify-items: center;
		grid-template-columns: 1fr 9fr;
		z-index: 2;
		position: relative;

		i {
			font-size: 15px;
			color: #8e8e93;
			cursor: pointer;
		}

		input {
			border: transparent;
			background-color: transparent;
			width: 100%;
			height: 100%;
			padding-right: 10px;
		}

		select:focus {
			outline: var(--primary);
		}
	}
}

.searchbar_column {
	position: relative;
	.search-container {
		background: white;
		width: 100%;
		z-index: 1;
		position: absolute;
		top: 60px;
		border-radius: 0px 0px $border_radius $border_radius;

		display: none;

		&.enabled {
			display: unset;
		}

		.default-caption,
		.loading-animation-container {
			color: #9b9b9b;
			padding: 20px;
		}

		.loading-animation-container {
			&::after {
				animation: loading 1s infinite linear;
				content: ".";
				font-size: 20px;
			}

			@keyframes loading {
				0% {
					content: ".";
				}
				50% {
					content: "..";
				}
				100% {
					content: "...";
				}
			}
		}

		ul {
			li {
				display: flex;
				align-items: center;
				cursor: pointer;

				padding: 20px;

				&:hover {
					background-color: #f8f8f8;
				}
				img {
					height: 40px;
					width: 40px;
				}

				span {
					padding-left: 10px;
				}
			}
		}
	}
}

select {
	background: #e8e9eb;
	border-radius: 4px;
	height: 64px;
	padding-left: 10px;
	position: relative;
	border: none;
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
	appearance: none;

	option {
		text-align: center;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		color: #9b9b9b;
	}
}

.inputContainer.input-error {
	margin: 5px 0px 0px 0px !important;
}

.input-error-description {
	font-size: 12px;
	color: #7f2e35;
	width: 90%;
	margin: 5px auto 0px auto;
}
