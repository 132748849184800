@import "../../../variables";

.product_card,
.recipe_card {
	&:hover {
		box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.12);
		transform: translateY(-2%);
	}
}
.product_card {
	width: 165px;
	height: 262px;

	background: #ffffff;
	@include border;
	display: grid;

	position: relative;

	grid-template-rows: minmax(auto, 130px) auto;

	margin-top: 20px;
	padding: 12px;

	transition: 0.3s transform;

	.productImageContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			max-width: 110px;
		}
	}
	.product_info {
		display: flex;
		flex-direction: column;
		span {
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			color: $black_font_color;
		}

		span.product_complement {
			font-size: 11px;
			color: $grey_support_font_color;
			margin-bottom: 8px;
		}
	}

	.product_price_container {
		display: grid;

		.product_price {
			font-style: normal;
			font-weight: 600;
			font-size: 11px;
			text-decoration-line: line-through;
			color: $grey_support_font_color;
		}

		.product_promo_price {
			font-style: normal;
			font-weight: 600;
			font-size: 22px;
			color: $black_font_color;
		}

		.save {
			font-size: 11px;
			color: $yellow_support_font_color;
			align-items: center;
			width: 85%;
			justify-content: space-between;
			display: flex;

			svg {
				path {
					stroke: $yellow_support_font_color;
				}
			}
		}
	}
}

.recipe_card {
	display: grid;
	grid-template-rows: 160px auto;
	width: 190px;
	height: 255px;

	margin-top: 20px;

	@include border;

	position: relative;

	overflow: hidden;

	&:hover {
		.recipe_image {
			img {
				transform: scale(1.2);
			}
		}

		.recipe_info {
			svg {
				path {
					&#minute {
						animation-play-state: running !important;
					}
					&#hour {
						animation-play-state: running !important;
					}
				}
			}
		}
	}

	@keyframes timer_minute {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotateZ(360deg);
		}
	}

	.recipe_image {
		width: 100%;

		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			position: relative;

			transition: 0.5s;
		}
	}

	.recipe_info {
		display: grid;
		margin: 10px;
		margin-left: 15px;
		grid-template-columns: 1fr;

		span {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			color: #222222;
		}

		.recipe_name {
			height: 35px;
		}

		.icons_container {
			height: 35px;
			font-size: 11px;
			font-weight: normal;

			display: flex;
			align-items: flex-end;

			.icons {
				display: grid;
				grid-template-columns: repeat(3, auto);
				column-gap: 5px;

				svg {
					width: 13px;
					height: 13px;
					align-self: center;

					path {
						&#hour {
							transform-origin: 49.1% 50%;
							animation: timer_minute 2.8s infinite linear backwards paused;
						}
						&#minute {
							position: absolute;
							top: 10px;
							right: 0;
							bottom: 0;
							left: 0;
							transform-origin: 49.1% 50%;
							animation: timer_minute 33.5s infinite linear paused;
						}
					}
				}
				span {
					font-style: normal;
					font-weight: 600;
					font-size: 11px;
				}

				.difficulty {
					font-size: 11px;
					color: #9b9b9b;
				}
			}
		}
	}
}
