@import "../../../variables";

.products_container {
	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		color: #000000;
		cursor: pointer;
	}

	.lastCard {
		width: 200px;
		height: 262px;

		background: #ffffff;
		display: flex;

		position: relative;

		margin-top: 20px;
		padding: 12px;

		transition: 0.3s transform;

		align-items: center;
		.content {
			p {
				margin-bottom: 10px;
			}
			height: fit-content;
			text-align: center;

			a {
				color: $grey_support_font_color;
				span {
					color: $primary;
				}
			}
		}
	}
}

.slick-slide * {
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

.category_card {
	&.notSelected {
		opacity: 0.4;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
	&.selected {
		p {
			color: $primary !important;
			text-align: center;
		}
		.category_image {
			border: solid 1px $primary;
			img {
				transform: scale(1.3) rotate(10deg);

				//animation: shaking 0.7s   0.2s;
			}
		}
	}
	.category_image {
		width: 100px;
		height: 100px;
		@include border;
		border-radius: 50%;
		margin: 0 auto;
		position: relative;
		overflow: hidden;

		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
			margin: 0 auto;
			position: relative;
			transition: 0.5s;
		}
	}

	&:hover {
		.category_image {
			img {
				transform: scale(1.3) rotate(10deg);
				//animation: shaking 0.7s   0.2s;
			}
		}

		// @keyframes shaking {
		//     0%,100%{
		//     }
		//     25%{
		//         transform:  rotate(-10deg) scale(1.2);
		//     }
		//     75%{
		//         transform:  rotate(10deg) scale(1.2);

		//     }

		// }
	}

	p {
		font-style: normal;
		font-weight: normal;
		font-size: 16.0042px;
		text-align: center;
		color: #222222;
	}
}

.container_padding {
	padding-right: 20px;
}

#category_container {
	.slick-track {
		.slick-slide {
			transition: 0.5s;
		}
	}
}

@media screen and(max-width: 700px) {
	.category_card {
		.category_image {
			width: 69px;
			height: 69px;
		}

		p {
			font-size: 11px;
		}
	}

	#banner_container {
		.slick-track {
			.slick-slide:not(.slick-current) {
				// -webkit-mask-image: -webkit-gradient(linear, left top, right bottom,
				// from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
				opacity: 0.4;
			}
		}
	}
}

.loading {
	.title {
		width: 250px;
		height: 35px;
		border-radius: $border_radius;
		cursor: unset;
	}

	.category_image,
	.categoryDescription,
	.productImageContainer,
	.product_promo_price,
	.product_complement,
	.product_name,
	.title {
		background-image: linear-gradient(
			-90deg,
			#e7edf1 0%,
			#f8f8f8 50%,
			#e7edf1 100%
		);
		background-size: 400% 400%;

		animation: shimmer 1s ease-in-out infinite;

		@keyframes shimmer {
			0% {
				background-position: 0% 0%;
			}
			100% {
				background-position: -135% 0%;
			}
		}
	}

	.product_card {
		.productImageContainer {
			width: 139px;
			height: 120px;
			border-radius: $border_radius;
		}
		.product_info {
			.product_name {
				width: 100%;
				height: 15px;
				border-radius: $border_radius;
			}

			.product_complement {
				width: 80%;
				height: 13px;
				margin-top: 5px;
				border-radius: $border_radius;
			}
		}

		.product_price_container {
			.product_promo_price {
				height: 35px;
				width: 60%;
				border-radius: $border_radius;
			}
		}

		&:hover {
			box-shadow: unset;
			transform: unset;
		}
	}

	.category_card {
		.categoryDescription {
			width: 80%;
			margin: 0 auto;
			margin-top: 10px;
			border-radius: 3px;
			height: 20px;
		}
	}
}
