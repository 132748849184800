@import "../../../../variables";

.cartContainer {
	min-width: 440px;
	height: 100%;

	background: #ffffff;
	box-shadow: -10px 0px 20px rgba(0, 0, 0, 0.02);

	padding: 5px 0;

	padding-bottom: 20px;
	right: 0;

	.cartContent {
		width: 90%;
		height: 100%;
		margin: 0 auto;

		position: relative;

		display: grid;
		grid-template-rows: min-content min-content 1fr min-content min-content;

		.cartHeaderCaption {
			display: flex;
			flex-direction: column;

			font-weight: 600;
			font-size: 18px;

			color: $black_font_color;

			span {
				font-size: 14px;
				line-height: 20px;

				color: $grey_support_font_color;
			}
		}

		.cartHeader {
			display: grid;
			grid-template-columns: 1fr min-content;
			padding: 25px 0px;

			button {
				width: 71px;
				height: 42px;

				display: flex;
				align-items: center;
				justify-content: center;

				border: 1px solid #e8e9eb;
				box-sizing: border-box;
				border-radius: 8px;

				.close-desktop-cart {
					font-family: "Nunito Sans";
					font-style: normal;
					font-weight: 600;
					color: #9b9b9b;
				}
			}
		}

		.cartIconContainer {
			padding: 25px 0px;
			display: flex;
			align-items: center;

			@include border;

			border-radius: unset;
			border-left: unset;
			border-right: unset;

			span {
				font-weight: 600;
				font-size: 18px;

				color: $black_font_color;

				margin-left: 15px;
			}
		}

		.itensContainer {
			overflow-y: auto;
		}

		.cartPriceContainer {
			height: 120px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			& > * {
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.caption {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				color: #9b9b9b;
			}
			.value {
				font-size: 14px;
				color: #222222;
			}
		}
	}
}

.mobileCartContainer {
	width: 100%;
	height: 0vh;

	position: fixed;
	bottom: 0px;

	left: 0;
	right: 0;
	margin: auto;

	background: #ffffff;
	box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.08);
	border-radius: 20px 20px 0px 0px;

	z-index: 99;

	transition: 0.8s;

	.mobileCartContent {
		width: 85%;
		margin: 30px auto;
		height: 80%;

		.mobileCartHeader {
			font-style: normal;
			font-weight: bold;
			color: #222222;

			display: flex;
			justify-content: space-between;
			align-items: center;

			span {
				font-size: 34px;
			}

			svg {
				cursor: pointer;
			}
		}
		.mobileCartItems {
			max-height: 88%;
			overflow-x: auto;
			#cartMessage {
				margin-top: 40%;
				p:last-of-type {
					width: 60%;
				}
			}

			.itemContainer {
				.infoContainer {
					span {
						width: 80%;

						font-size: 14px;
					}
				}
				.quantityContainer {
					.addToCartContainer {
						.quantity_container {
							input {
								padding-left: unset;
							}
						}
					}
				}
			}
		}
	}

	&.opened {
		height: 85vh;

		.mobileCartButton {
			transform: translateY(40px);
			-webkit-transition: all 0.4s ease-in-out;
			-moz-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;
		}
	}

	@media screen and( min-width: 700px) {
		& {
			height: 0vh !important;
		}
	}
}

#cartMessage {
	height: 100%;
	display: flex;
	justify-content: center;
	align-self: center;
	flex-direction: column;

	p {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 22px;
		color: #222222;

		margin: 0 auto;

		&:last-of-type {
			font-size: 14px;
			width: 80%;
			margin: 0 auto;
			padding-top: 20px;
			color: #9b9b9b;
			text-align: center;
		}
	}
}

.itemContainer {
	padding: 30px 0px;
	display: grid;
	grid-template-columns: min-content 1fr min-content;
	.imageContainer {
		img {
			width: 80px;
		}
	}
	.infoContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;

		padding-left: 10px;

		span {
			font-size: 16px;
			width: 90%;
			color: $black_font_color;
		}
		.price {
			font-weight: 600;
			font-size: 18px;
		}
	}

	.quantityContainer {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
