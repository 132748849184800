@import "../../../variables";
@import "./support_classes";

button {
	background: none;
	border: none;
}

.neutral {
	width: 343px;
	height: 48px;
	@include border;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	text-align: center;

	color: #9b9b9b;

	span {
		margin: 0 auto;
	}
}
.Button {
	border-radius: 6px;

	font-size: 14px;

	width: 343px;
	height: 48px;

	display: grid;
	grid-template-rows: 1fr;
	align-items: center;
	justify-items: center;

	transition: all 0.8s;

	cursor: pointer;

	i {
		font-size: 20px;
	}

	span {
		justify-self: left;
	}

	&.normal:active {
		-webkit-box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
		box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
	}

	&.cart {
		background: #2662f0;
		color: #f5f6f7;
		grid-template-columns: 1fr 2fr 1fr;

		span:last-of-type {
			background: rgb(58, 112, 240);
			border-radius: 2px;
			width: 25px;
			height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.AddWithPlaceholder {
		background: #2662f0;
		color: #f5f6f7;
		display: flex;
		justify-content: space-between;
		padding: 0 20px;
		span:last-of-type {
			background: rgb(58, 112, 240);
			border-radius: 2px;
			padding: 5px;
			height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.normal {
		background: #2552f0;
		color: #f5f6f7;
		box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.08);

		span {
			justify-self: center;
			font-family: "Nunito Sans";
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 20px;
			color: #f5f6f7;
		}
	}

	&.list {
		border: 1px solid #f5f6f7;
		box-sizing: border-box;
		border-radius: 4px;
		grid-template-columns: 1fr 3fr;
		color: #9b9b9b;
	}

	&.exit {
		background: #fee9e7;
		color: $red;
		box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.08);

		span {
			justify-self: center;
		}
	}

	&.exit:active {
		-webkit-box-shadow: inset 1px 1px 10px rgb(254, 233, 231);
		-moz-box-shadow: inset 1px 1px 10px rgb(254, 233, 231);
		box-shadow: inset 1px 1px 10px rgb(254, 233, 231);
	}

	&.delete {
		border: solid 1px $red;
		color: $red;
		span {
			justify-self: center;
		}
	}

	&.disabled {
		color: #cccc;
		background-color: #8b8b8b;
		box-shadow: none;

		span {
			justify-self: center;
			font-family: "Nunito Sans";
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 20px;
			justify-self: center;
		}

		.button-loading-animation {
			border: 4px solid #f3f3f3;
			border-top: 4px solid #ccc;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			animation: spin 2s linear infinite;
		}

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}

	&.success {
		background: green;
		color: #f5f6f7;
		box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.08);

		span {
			justify-self: center;
			font-family: "Nunito Sans";
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 20px;
			color: #f5f6f7;
		}
	}

	&.success,
	&.disabled {
		pointer-events: none;
	}
}

.addToCartContainer {
	width: 42px;
	height: 42px;

	@include border;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	color: $primary;
	position: absolute;
	right: 15px;
	top: 30%;

	background-color: white;

	transition: 0.2s;

	&.HoverState {
		width: 100px;
		border-radius: $border_radius;
		position: unset;

		position: relative;

		.quantity_container {
			transition: 1s;
			opacity: 1;
			display: grid;
		}

		#plus_button {
			opacity: 0;
			display: none;
		}
	}

	.quantity_container {
		display: none;
		opacity: 0;
		position: absolute;
		grid-template-columns: auto auto auto;
		grid-template-rows: 1fr;
		padding: 10px;

		input {
			width: 50px;
			border: none;
			text-align: center;
			background-color: transparent;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			color: #000000;

			padding-left: 15px;
		}
		button {
			border: none;
			background-color: transparent;

			i {
				color: $primary;

				cursor: pointer;
			}
		}
	}

	&:hover {
		#plus_button {
			animation: HandleHoverAddToCart 0.2s forwards;
		}

		width: 100px;
		border-radius: $border_radius;

		.quantity_container {
			transition: 1s;
			opacity: 1;
		}
	}

	@keyframes HandleHoverAddToCart {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
			display: none;
		}
	}

	@keyframes ExpandAddToCartContainer {
		from {
			width: 42px;
		}
		to {
			width: 100px;
			border-radius: $border_radius;
		}
	}
}

.buttonQuantityContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: center;
	align-content: center;

	@include border;
	width: 104px;
	height: 42px;

	button {
		width: 30px;

		i {
			color: $primary;
			cursor: pointer;
		}
	}

	input {
		width: 100%;
		text-align: center;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	button,
	input {
		border: none;
		background: none;
	}
}

.mobileCartButton {
	height: 50px;
	width: 88%;
	position: fixed;
	background-color: $primary;
	bottom: 0px;
	opacity: 0;

	left: 0;
	right: 0;
	margin: auto;

	display: grid;

	grid-template-columns: min-content 1fr minmax(50px, auto) !important ;
	padding: 0 20px;

	font-style: normal;
	font-weight: bold;
	font-size: 14px;

	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;

	color: #e8e9eb;
	span {
		&.cartQuantityContainer {
			background: #3a70f0;

			border-radius: 2px;
			width: 25px;
			height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&.placeholderContainer {
			padding-left: 10px;
		}

		&.cartValueContainer {
			width: auto !important;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;

			padding: 10px 12px;
			color: #ffffff;
		}
	}

	// Animação de "entrada" do botão de carrinho  no mobile
	@media screen and (max-width: 700px) {
		& {
			animation: mobileCartButtonIn 0.8s forwards;
		}
	}

	@media screen and (max-width: 500px) {
		& {
			bottom: 65px !important;
		}
	}

	// Animação de "saída" do botão de carrinho no mobile
	@media screen and (min-width: 700px) {
		& {
			animation: mobileCartButtonOut 0.8s forwards;
		}
	}

	@keyframes mobileCartButtonIn {
		0% {
			bottom: 0px;
			opacity: 0;
		}
		100% {
			bottom: 15px;
			opacity: 1;
		}
	}
	@keyframes mobileCartButtonOut {
		0% {
			bottom: 15px;
			opacity: 1;
		}
		100% {
			bottom: 0px;
			opacity: 0;
		}
	}

	&.mobileCartButtonOut {
		animation: mobileCartButtonOut 0.8s forwards;
	}
}

.closeModalButton {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 999;

	cursor: pointer;

	&.disabled {
		pointer-events: none;
	}
}
