@import "../../variables";

#homePage {
	#home {
		display: grid;

		grid-template-columns: 1fr;
		grid-template-columns: minmax(10%, min-content);

		grid-row-gap: 40px;

		width: 100%;
		height: 100%;

		#discount_container {
			.slick-track {
				.slick-slide {
					img {
						width: 388px;
						border-radius: 8px;
					}
				}
			}
		}

		.slider-promo {
			.slick-track {
				.slick-slide {
					img {
						width: 242px;
						border-radius: 8px;
					}
				}
			}
		}

		@media screen and (max-width: $break_minimum) {
			#bannerTopo {
				display: none;
			}

			#slides_container {
				display: none;
			}

			section#discount_container {
				display: none;
			}
		}
	}
}
