@import "../../../variables";

.page_container {
	.main {
		height: 100%;
		padding: 30px 0;
		.itemsContainer {
			height: 100%;

			p.subtitle {
				font-weight: 600;
				font-size: 12px;
				color: #9b9b9b;
			}

			#cartItemsAndOptionsContainer {
				margin-top: 25px;

				display: flex;
				flex-direction: row;

				.cartItemsContainer {
					max-width: 80%;
					min-width: 50%;
					width: 1000px;
					background: #ffffff;
					padding: 28px;

					height: fit-content;

					padding-bottom: 0px;
					border: 1px solid #e8e9eb;
					box-sizing: border-box;
					border-radius: 8px;

					.header {
						display: flex;
						flex-direction: column;
						height: 45px;
						margin-bottom: 25px;

						span {
							font-family: "Nunito Sans";
							font-style: normal;
							font-weight: 600;
							font-size: 18px;
							color: #222222;
						}

						span:nth-of-type(2) {
							font-size: 14px;
							color: #9b9b9b;
						}
					}

					.itemsList {
						table {
							width: 100%;
							border-collapse: collapse;
							thead {
								tr th {
									border-spacing: 0;
									border-bottom: 1px solid #e8e9eb;
									padding-bottom: 20px;
								}
							}
							tbody {
								tr {
									height: 100px;
								}
								#tdImage {
									img {
										width: 80px;
										height: auto;
									}
								}
								#tdInfo {
								}
								#tdUnityPrice {
									padding: 0 30px;
									text-align: center;
								}
								#tdQuantity {
									.quantityContainer {
										display: flex;
										justify-content: center;
										.addToCartContainer {
											&.HoverState {
												position: unset;
											}
										}
									}
								}
								#tdTotal {
									text-align: center;
								}
							}

							@media screen and (max-width: 950px) {
								& {
									thead {
										#thUnityPrice {
											display: none;
										}
									}
									tbody {
										#tdUnityPrice {
											display: none;
										}
									}
								}
							}
						}

						#cartMessage {
							padding: 20px 0 50px 0;

							p {
								&:nth-of-type(2) {
									padding-top: 10px;
									width: 42%;
								}
							}
						}
					}

					.cartValueContainer {
						border-top: 1px solid #e8e9eb;

						height: 140px;

						display: flex;

						align-items: center;

						justify-content: flex-end;
						.cartValue {
							width: 180px;

							height: max-content;

							padding: 10px 15px;

							.caption {
								font-style: normal;
								font-weight: 600;
								color: #9b9b9b;
							}

							.total,
							.subtotal {
								font-style: normal;
								font-weight: 600;
								font-size: 14px;
								color: #222222;
							}

							.total {
								font-size: 18px;
							}

							& > * {
								display: flex;
								justify-content: space-between;
								align-items: center;
							}
							.subtotalContainer {
								margin-bottom: 10px;
							}
						}
					}
				}

				.optionsContainer {
					max-width: 40%;
					min-width: 30%;
					margin-left: 35px;

					position: relative;

					.card {
						height: 130px;
						width: 100%;
						border: 1px solid #e8e9eb;
						border-radius: 8px;

						margin-bottom: 25px;

						display: flex;
						align-items: center;

						position: relative;

						cursor: pointer;

						// transition: box-shadow 0.8s transform 0.3s opacity
						// 	0.3s ease-in-out;

						transition: all 0.3s;

						box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.08);

						// display: none;

						.content {
							width: 85%;
							margin: 0 auto;

							display: flex;
							flex-direction: row;

							.caption {
								display: flex;
								flex-direction: column;
								justify-content: center;
								padding-left: 20px;

								.mainCaption {
									font-weight: 600;
									font-size: 16px;
									color: #222222;
								}

								.complementCaption {
									font-weight: 600;
									font-size: 12px;
									color: #9b9b9b;
								}
							}
							img,
							svg {
								width: 53px;
							}
						}

						& > * {
							pointer-events: none;
						}

						&:hover {
							transform: translateY(-2%);
						}

						&::after {
							content: "";

							position: absolute;

							transition: all 0.5s;

							right: -1px;
							bottom: -2px;
						}

						&::before {
							content: "";
							position: absolute;
							transition: all 0.5s;
							top: 0;
							left: -1px;
						}

						&.selectedOption::before {
							animation: optionButtonBorderAnimation 0.4s forwards;
						}
						&.selectedOption::after {
							animation: optionButtonSideBordersAnimation 0.4s forwards;
						}

						&.selectedOption {
							box-shadow: unset;
							&:hover {
								transform: translateY(0%);
							}
						}

						&.notSelectedOption {
							box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.08);
							background-color: #f7f7f7;
							opacity: 0.8;

							&:hover {
								box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.12);
							}

							svg {
								filter: grayscale(1);
							}
						}

						@keyframes optionButtonBorderAnimation {
							0% {
								width: 0%;
								height: 0%;
								border-top: solid 1.8px $primary;
								border-left: solid 1.8px $primary;
								border-radius: 8px 0 0px 0px;
							}
							100% {
								width: 100%;
								height: 100%;
								border-top: solid 1.8px $primary;
								border-left: solid 1.8px $primary;
								border-radius: 8px 8px;
							}
						}

						@keyframes optionButtonSideBordersAnimation {
							0% {
								width: 0%;
								height: 0%;
								border-bottom: solid 1.8px $primary;
								border-right: solid 1.8px $primary;
								border-radius: 0 0 8px 8px;
							}

							100% {
								width: 100%;
								height: 100%;
								border-bottom: solid 1.8px $primary;
								border-right: solid 1.8px $primary;
								border-radius: 0 8px 8px 8px;
							}
						}

						&.cardOut {
							animation: cardOutAnimation 0.7s forwards;
						}
						@keyframes cardOutAnimation {
							0% {
								transform: translateY(5px);
							}

							50% {
								transform: translateY(-10px);
							}

							80% {
								opacity: 1;
							}
							100% {
								transform: translateY(-10px);
								opacity: 0;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.itemsContainer {
		width: 95% !important;
		h1 {
			font-size: 24px !important;
		}
		h1,
		p {
			text-align: center;
		}
		p {
			margin-top: 5px;
			font-size: 14px !important;
		}

		.cartItemsContainer {
			display: none;
		}
		.optionsContainer {
			width: 100%;
			max-width: unset !important;
			min-width: unset !important;
			margin: 0 !important;

			.deliveryOptionsContainer {
				display: flex;
				justify-content: space-between;
				& > * {
					float: left;
					width: 47% !important;
					margin: 0 auto;
					height: 190px !important;
					flex-direction: row;
					.content {
						flex-direction: column !important;
						align-items: center;

						.caption {
							margin-top: 25px;
							padding: 0 !important;

							text-align: center;
							.mainCaption {
								width: 100% !important;
								font-size: 14px !important;
							}
							.complementCaption {
								margin-top: 10px;
								width: 100% !important;
							}
						}
					}
				}
			}
		}
	}
}
