@import "../../../../variables";

#headerMobile {
	padding: 10px;
	height: fit-content !important;
	.headerMobileContent {
		width: 100%;
		height: 100%;

		.clientInfo {
			display: grid;
			grid-template-columns: min-content 1fr;
			align-items: center;
			& > * {
				display: flex;
				align-items: center;
			}

			padding: 20px 0;

			.avatarContainer {
				width: 80px;
				justify-content: center;
			}

			.adressContainer {
				width: max-content;
				height: fit-content;
				display: grid;
				grid-template-rows: repeat(2, min-content);
				grid-template-columns: auto min-content;

				font-family: Nunito Sans;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;

				color: $black_font_color;

				.caption {
					color: $grey_support_font_color;
				}

				.adress {
					grid-row: 2;
				}

				.arrow {
					width: 30px;
					grid-row: 2;
					grid-column: 2/3;

					display: flex;
					justify-content: center;

					cursor: pointer;
				}
			}
		}
	}
}
