.modal-return-message,
.modalCreateAccountContainer,
.modalFormLoginContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
}

.inputContainer {
	height: 64px;
}

.createAccountContainer {
	max-height: 90vh;
}

.return-message-container,
.createAccountContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 500px;
	//height: 650px;

	background-color: #ffffff;
	box-sizing: border-boxmax;
	-moz-box-sizing: border-box;

	-webkit-box-sizing: border-box;
	border-radius: 8px;

	padding: 2px;

	.return-message-content,
	.createAccountContent {
		position: relative;

		.closeModalIcon {
			position: absolute;
			right: 0;
			top: 0px;
			cursor: pointer;

			&.disabled {
				pointer-events: none;
				filter: opacity(0.5);
			}
		}
		.title-container,
		.titleSearchContainer {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				font-weight: 600;
				font-size: 24px;
				color: #222222;
				text-align: center;
				line-height: 120%;
				max-width: 90%;

				margin-top: 5px !important;
			}
		}
		.inputSearchContainer {
			margin-top: 15px;
			display: grid;
			overflow: auto;
			.passwordContainer,
			.cpfrgContainer {
				display: flex;
				justify-content: space-between;

				// .inputContainer {
				// 	width: 49%;
				// }
			}

			select,
			.inputContainer {
				margin: 5px 0 5px 0;
			}

			.numberAndComplementContainer {
				display: grid;
				grid-template-columns: 1fr 3fr;
				column-gap: 5px;
			}
			.districtCityAndStateContainer {
				display: grid;
				grid-template-columns: 2fr 1fr 0.7fr;
				column-gap: 5px;
			}

			.cityAndStateContainer,
			.numberAndComplementContainer {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				div#siteState,
				div#siteNumber {
					width: 20%;
				}
				div#siteCity,
				div#siteAddress {
					width: 80%;
					margin-left: 5px;
				}

				div#siteState {
					margin-left: 5px;
				}

				div#siteCity {
					margin: unset;
				}
			}
		}

		.create-account {
			margin: 10px 0 10px 0;
			text-align: center;

			span {
				color: #2662f0;
				cursor: pointer;
			}
		}
	}
}

.formSettingsContainer,
.formLoginContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 450px;
	height: auto;

	background-color: #ffffff;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	border-radius: 8px;

	padding: 15px;

	.formSettingsContent,
	.formLoginContent {
		width: 100%;
		height: 100%;

		position: relative;
		box-sizing: border-box;

		.closeModalIcon {
			position: absolute;
			right: 0;
			top: 0px;
			cursor: pointer;
		}
		.formDataContainer,
		.illustrationContainer {
			width: 100%;
			height: 40%;
		}

		.illustrationContainer {
			display: flex;
			justify-content: center;
		}
		width: 100%;

		padding-top: 15px;

		display: flex;
		flex-direction: column;

		.titleSearchContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				font-weight: 600;
				font-size: 24px;
				color: #222222;
				text-align: center;
				line-height: 120%;
				max-width: 70%;

				margin-top: 5px !important;
			}
		}

		.inputSearchContainer {
			margin-top: 15px;
			display: grid;
			row-gap: 5px;
		}

		.create-account {
			margin: 10px 0 10px 0;
			text-align: center;

			span {
				color: #2662f0;
				cursor: pointer;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	#modalContainer.opened.modalFormLogin {
		display: block !important;
		.formLoginContainer {
			width: 100% !important;
			height: 95% !important;

			border-radius: 12px 12px 0 0 !important;

			animation: openInMobile 0.8s forwards;

			@keyframes openInMobile {
				0% {
					transform: translateY(100%);
				}
				100% {
					transform: translateY(3%);
				}
			}
		}
	}
}
