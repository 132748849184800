#pageByGroups,
#pageByCategories {
	#products {
		.content {
			.categoriesListContainer {
				.categoriesSlicker {
					.category_card {
						.category_image {
							width: 70px !important;
							height: 70px !important;
						}
						p {
							max-width: 70px;
							font-size: 14px;
							padding-top: 5px;
							text-align: center;
						}
					}
				}
			}
			.selectedCategorieContainer {
				margin-top: 50px;
				.sectionTitle {
					margin-top: 20px;
					text-transform: capitalize;
				}

				section.productsByGroupListContainer {
					margin-top: 30px;
				}
			}

			.groupItensContainer {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				.product_card {
					// float: left;
					margin-right: 25px;
				}
			}
		}
	}
}

#pageByGroups {
	.content {
		.title {
			margin-top: 50px;
		}

		.productsFromGroup {
			display: flex;
			flex-wrap: wrap;
			.product_card {
				margin-right: 20px !important;
			}

			margin-top: 10px;
			.sectionTitle {
				margin-top: 20px;
				text-transform: capitalize;
			}

			section.productsByGroupListContainer {
				margin-top: 30px;
			}
		}
	}
}
