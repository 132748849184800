@import '../../../variables';

.banner{
  display:flex;
  width: 100%;
  column-gap: 10px;
  
  overflow: hidden;
  position: relative;
  flex-wrap:wrap;

  row-gap: 10px;
  flex-basis: content;

  padding:1px;
  img{
     flex-grow: 1;
     height: auto;
     position: relative;
     border-radius: $border_radius;
  }
}
