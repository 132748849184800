@import "../../../../variables";

.mainMenuContainer {
	height: fit-content;
	background: #ffffff;
	border-radius: 8px;

	width: 446px;

	.mainMenuContent {
		width: 85%;
		height: 100%;
		margin: 0 auto;

		position: relative;

		.mainMenuHeader {
			width: 100%;
			height: 15vh;

			max-height: 100px;

			display: grid;

			grid-template-columns: min-content 1fr min-content;

			align-content: center;

			// Todos os itens que sao filhos diretos de .mainMenuHeader
			// recebem essas propriedades

			& > * {
				display: flex;
				align-items: center;
			}

			.clientAvatar {
				width: 60px;
			}

			.clientName {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				padding-left: 20px;
			}

			.closeModalOption {
				width: 40px;
				justify-content: center;

				button {
					border: none;
					background: none;

					display: flex;
					align-items: center;
					justify-content: center;

					svg {
						cursor: pointer;

						&:active {
							path {
								opacity: 0.6;
								transform: scale(0.9);
							}
						}
					}

					&:active {
						outline: 0;
					}
				}
			}
		}

		.mainMenuOptions {
			width: 100%;

			ul {
				list-style: none;
				@include border;

				li {
					width: 100%;
					position: relative;

					&.optionDisabled {
						filter: grayscale(1);
						pointer-events: none;
						opacity: 0.5;
					}

					&::before {
						content: "";
						width: 0;
						height: 100%;
						z-index: 2;
						position: absolute;

						transition: 0.3s ease-in-out;
					}

					&:not(.optionDisabled):hover::before {
						background: #f8f8f8;
						width: 100%;
					}

					&:hover:not(.optionDisabled) {
						a {
							.optionDotContainer {
								.optionDot {
									background: $primary;
									opacity: 0.8;
								}
							}
						}
					}

					a {
						display: grid;
						grid-template-columns: min-content 1fr;
						height: 11vh;
						max-height: 80px;
						align-items: center;

						font-weight: 600;
						font-size: 18px;
						color: #222222;

						position: relative;
						z-index: 3;

						.optionDotContainer {
							width: 76px;
							display: flex;
							justify-content: center;

							.optionDot {
								width: 24px;
								height: 24px;
								background: #e8e9eb;
								border-radius: 50%;
								transition: 0.3s ease-in-out;
							}
						}
					}

					&:not(:first-of-type) {
						border-top: 1px solid #e8e9eb;
					}
				} // final LI
			} // final UL

			.Button {
				margin-top: 15px;
				margin-bottom: 10px;
			}
		} // final .mainMenuOptions
	}
}
