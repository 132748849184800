// colors
$grey_support: #e8e9eb;
$primary: #2662f0;
$red: #f01f0e;

// fonts
$grey_support_font_color: #9b9b9b;
$black_font_color: #222222;
$yellow_support_font_color: #ffbe15;
$border_radius: 8px;

@mixin border() {
	border: solid 1px $grey_support;
	border-radius: $border_radius;
	box-sizing: border-box;
}

@mixin borderBottom() {
	border-bottom: solid 1px $grey_support;
	box-sizing: border-box;
}

@mixin buttonBoxShadow() {
	box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.08);
}
// breakpoints

$break_minimum: 500px;
$break_medium: 1100px;

$max_width: 1198px;
