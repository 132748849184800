@import "header";
@import "../../../variables";
@import "support_classes";
@import "checkoutPage";

a {
	text-decoration: none;
	color: #2662f0;
	:visited {
		color: none;
	}
}

.page_container {
	display: grid;
	grid-template-areas:
		"header"
		"body"
		"footer";
	grid-template-rows: min-content 1fr min-content;
	height: 100%;
	transition: 1s;
	width: 100%;

	//    @media only screen and (max-width: $break_minimum) {
	//       animation: menu_desktop_out 1s forwards;
	//    }

	//    @keyframes menu_desktop_out{
	//       from{
	//          transform: translateY(0);
	//       }
	//       to{
	//          transform: translateY(-100%);
	//       }
	//    }
}

.main {
	grid-area: body;
	width: 100%;
	height: 100%;
	position: relative;
	min-width: 0;

	padding-bottom: 20px;

	.itemsContainer {
		@media screen and (max-width: 500px) {
			width: 100%;
			padding-left: 10px;
		}
	}
}

footer {
	grid-area: footer;
	position: relative;
	border-top: 1px solid $grey_support;

	.marketInfoContainer {
		width: 90%;
		max-width: 1430px;
		height: 180px;
		margin: 0 auto;
		position: relative;

		.marketInfoContent {
			width: 94%;
			max-width: 1310px;
			float: right;
			height: 100%;

			display: grid;
			align-items: center;
			justify-content: center;
			grid-template-columns: 140px 1fr 250px;

			@include borderBottom;

			.logoContainer,
			.marketData,
			.socialMedia {
				height: 130px;
			}

			.logoContainer {
				display: flex;
				justify-content: center;
				svg {
					width: 110px;
				}
			}

			.marketData {
				height: fit-content;
				padding: 20px 0;
				.market_name {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					color: #222222;
				}
				.adress {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					color: #9b9b9b;
					margin-top: 15px;
				}
				.to_map {
					margin-top: 15px;
					color: $primary;
				}
			}

			.socialMedia {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.socialMediaContent {
					display: flex;
					justify-content: flex-end;
					align-content: flex-start;
					flex-direction: column;

					.title {
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						color: #222222;
						text-align: right;
					}

					.icons {
						font-size: 30px;
						display: flex;
						justify-content: space-between;
						padding-top: 20px;
						width: fit-content;
						align-self: flex-end;
						position: relative;

						@media screen and (max-width: 600px) {
							margin: 0 auto;
						}

						a {
							color: #222222;

							&:last-of-type {
								i {
									@media screen and (max-width: 600px) {
										padding-right: 0;
									}
								}
							}

							i {
								font-size: 30px;
								cursor: pointer;

								transition: 0.2s;
								width: auto;
								padding-left: 15px;

								@media screen and (max-width: 600px) {
									padding-right: 15px;
									padding-left: 0;
								}

								&:hover {
									transform: translateY(-2px);
									//    width: 100px;
								}
								&.fa.fa-twitter:hover {
									color: #00acee !important;
								}

								&.fa.fa-facebook:hover {
									color: #3b5998 !important;
								}

								&.fa.fa-instagram:hover {
									background: -webkit-linear-gradient(
										45deg,
										#f09433 0%,
										#e6683c 25%,
										#dc2743 50%,
										#cc2366 75%,
										#bc1888 100%
									);
									-webkit-background-clip: text;
									-webkit-text-fill-color: transparent;
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
								}

								&.fa.fa-youtube,
								&.fa.fa-youtube-play:hover {
									color: #c4302b;
								}

								&.fa.fa-linkedin:hover {
									color: #0e76a8;
								}
							}
						}
					}
				}
			}

			@media screen and (max-width: 680px) {
				grid-template-columns: unset;
				grid-template-areas: "logo marketData" "socialMedia socialMedia";

				height: 100%;

				.logoContainer {
					grid-area: logo;
					justify-content: center;
				}
				.marketData {
					grid-area: marketData;
				}
				.socialMedia {
					grid-area: socialMedia;
					height: 100%;
					padding: 10px 0;
					justify-content: center;

					.socialMediaContent {
						.socialMediaContent {
							.icons {
								align-self: center;
							}
						}
					}
				}
			}
		}

		& > div {
			div.description {
				display: grid;
				height: 164px;
				padding: 20px 0;
				grid-template-columns: 7fr 3fr;
				border-bottom: 1px solid $grey_support;

				@media screen and (max-width: $break_minimum) {
					height: auto;

					grid-template-rows: 1fr 1fr;
					grid-template-columns: auto;
					grid-row-gap: 10px;
				}

				.content {
					display: grid;
					grid-template-rows: 4fr 1fr;
					grid-template-columns: minmax(auto, 120px) 8fr;

					.logo_container {
						align-self: center;
					}

					.market_data {
						width: 40%;

						@media screen and (max-width: 800px) {
							width: 80%;
						}
					}
				}
			}
		}

		@media screen and (max-width: 680px) {
			height: 260px;
		}
	}
	.poweredBy {
		width: 100%;
		height: 10%;
		max-height: 50px;
		bottom: 0;
		background-color: $grey_support;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		right: 0;

		@media screen and (max-width: 600px) {
			position: relative;
		}
	}
	.complement {
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			width: 50%;
			margin: 0 auto;
			font-style: normal;
			font-weight: normal;
			font-size: 11px;
			text-align: center;
			color: #9b9b9b;

			@media screen and (max-width: 890px) {
				width: 80%;
			}
		}
	}
}

.menu_mobile_row {
	width: 100%;
	height: 50px;
	bottom: 0;
	border-top: solid 1px $grey_support;
	background-color: #ffffff;
	position: fixed;
	z-index: 2;

	@media only screen and (max-width: $break_minimum) {
		animation: menu_mobile_in 1s forwards;
	}

	@media only screen and (min-width: $break_minimum) {
		animation: menu_mobile_out 1s forwards;
	}

	@keyframes menu_mobile_in {
		from {
			transform: translateY(100%);
		}
		to {
			transform: translateY(0);
		}
	}

	@keyframes menu_mobile_out {
		from {
			transform: translateY(0);
		}
		to {
			transform: translateY(100%);
		}
	}

	.menu_mobile_container {
		width: 90%;
		height: 100%;
		display: grid;
		align-content: center;
		justify-content: space-between;
		grid-template-columns: repeat(4, auto);
		margin: 0 auto;

		font-size: 11px;
		color: #9b9b9b;
		cursor: pointer;

		& > * {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			width: fit-content;
			float: left;
			width: 55px;
			height: 35px;
		}

		& > * svg {
			width: 17px;
		}
	}
}

.logo_container {
	width: 100%;
	svg {
		width: 80px;
		margin: 0 10px;
		transition: 1s;

		@media only screen and (max-width: 600px) {
			width: 80px;
		}
	}
}
