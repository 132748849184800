@import "../../../variables";

// @mixin teste (){ para usar --> @include  teste
//     display:grid;
//     background-color:pink
// };

header#header_desktop {
	grid-area: header;
	max-height: 150px;
	width: 100%;
	height: 150px;
	display: grid;
	grid-template-rows: 90px 60px;
	transition: 0.5s transform, 0.5s opacity 800ms, 0.5s height 800ms;
	background: #ffffff;

	animation-play-state: paused;
	position: relative;
	z-index: 2;

	@include border;

	@media only screen and (max-width: $break_minimum) {
		animation: menu_desktop_out 1s forwards;
	}

	@media only screen and (min-width: $break_minimum) {
		animation: menu_desktop_in 1s forwards;
		display: grid !important;
	}

	@keyframes menu_desktop_out {
		from {
			transform: translateY(0);
		}
		to {
			transform: translateY(-100%);
		}
	}

	@keyframes menu_desktop_in {
		from {
			transform: translateY(-100%);
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.menu {
		.itemsContainer {
			display: grid;
			grid-template-columns: auto auto 3fr 1fr 1fr 1fr;
			grid-template-rows: 1fr;
			height: 100%;
			align-content: center;
			column-gap: 20px;

			@media only screen and (max-width: $break_medium) {
				grid-template-columns: auto auto 2fr 1fr 1fr;
			}

			@media only screen and (max-width: 850px) {
				grid-template-columns: auto auto 3fr 1fr;
			}

			@media only screen and (max-width: 700px) {
				grid-template-columns: auto auto 3fr;
			}

			& > * {
				height: 100%;
				display: flex;
				align-items: center;
			}

			.menu_hamburguer_column {
				i {
					font-size: 30px;
					cursor: pointer;

					@media only screen and (max-width: 600px) {
						font-size: 30px;
					}
				}
			}

			.address_column {
				@media only screen and (max-width: $break_medium) {
					display: none;
				}

				.address_container {
					width: 184px;
					height: 40px;
					display: grid;
					grid-template-areas:
						"caption arrow"
						"address arrow";

					margin: 0 auto;

					position: relative;
					.caption {
						grid-area: caption;

						font-weight: 600;
						font-size: 14px;
						color: #9b9b9b;
					}

					.address {
						grid-area: address;
						font-weight: bold;
						font-size: 14px;
						overflow: hidden;
						top: 0;

						p {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}

						&:hover {
							p {
								animation: showAllAddressText 15s 0.3s infinite backwards;

								@keyframes showAllAddressText {
									0% {
										transform: translateX(0%);
										overflow: unset;
									}

									100% {
										transform: translateX(-110%);
										overflow: unset;
									}
								}
							}
						}
					}

					#arrow {
						grid-area: arrow;
						align-self: center;
						font-weight: bold;
						cursor: pointer;
					}

					.addressMenuContainer {
						@include border;
						@include buttonBoxShadow;
						background: #ffffff;
						position: absolute;
						top: 110%;

						.addressContainer {
							display: flex;
							flex-direction: row;
							align-items: center;
							padding: 15px;
							height: 80px;

							&:hover {
								label {
									span.title {
										animation: showAllAdressesText 20s 0.5s infinite backwards;
									}
								}
							}

							label {
								display: flex;
								flex-direction: column;
								margin-left: 5%;
								overflow: hidden;

								.title {
									font-weight: 600;
									font-size: 14px;
									color: #222222;
								}

								.complement {
									font-size: 12px;
									color: #9b9b9b;
								}

								span {
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
								}

								@keyframes showAllAdressesText {
									0% {
										transform: translateX(0%);
										overflow: unset;
									}

									100% {
										transform: translateX(-110%);
										overflow: unset;
									}
								}
							}
						}
					}
				}
			}

			.buylist_button_container {
				@media only screen and (max-width: 850px) {
					display: none;
				}
			}

			.cart_button_container {
				@media only screen and (max-width: 700px) {
					display: none;
				}
			}

			.searchresults-container {
				max-height: 400px;
				overflow-y: auto;
				&::-webkit-scrollbar {
					width: 8px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #ccc;
					border-radius: 20px;
				}
			}
		}
	}

	.sub_menu {
		height: 60px;
		border-top: 1px solid #e8e9eb;
		width: 100%;

		.itemsContainer {
			grid-template-columns: auto 9fr;
			& > nav,
			ul {
				list-style: none;
				color: #001c65;
				font-weight: bold !important;
				font-size: 14px;

				& > li {
					i {
						padding-right: 10px;
					}

					cursor: pointer;
				}
				ul {
					display: flex;
					color: #222222;
				}
			}

			nav {
				&:hover nav#categories-list {
					display: block;
				}
			}

			.mainly-sectors {
				ul {
					li {
						border-left: 1px solid #e8e9eb;
						height: 100%;
						width: 100%;
						padding: 10px 34px;

						@media screen and(max-width:700px) {
							padding: 10px 20px;
						}

						a {
							color: inherit;

							&:hover {
								color: var(--primary);
							}
						}
					}
				}
			}
		}

		.itemsContainer {
			@media screen and(max-width:850px) {
				display: flex;
				justify-content: center;
			}
		}
	}
}

header#headerMobile {
	grid-area: header;
	position: relative;
	z-index: 1;
	transition: 0.5s height, 1.5s opacity 0.7s;
	opacity: 0;

	@media only screen and (max-width: $break_minimum) {
		animation: header_mobile_show 0.5s forwards;
		opacity: 1;
	}

	@media only screen and (min-width: $break_minimum) {
		animation: header_mobile_hide 0.5s forwards;
		opacity: 0;
	}

	@keyframes header_mobile_show {
		0% {
			height: 150px;
		}
		100% {
			height: 200px;
		}
	}

	@keyframes header_mobile_hide {
		from {
			height: 200px;
		}
		to {
			height: 150px;
		}
	}
}

header#cleanHeader {
	height: 150px;
	width: 100%;
	height: 100px;
	background: #ffffff;

	position: relative;
	z-index: 2;

	border-bottom: 1px solid #e8e9eb;
	display: flex;
	align-items: center;
	.menu {
		width: 100%;
		.itemsContainer {
			width: 90%;
			height: 100%;
			align-content: center;
		}
	}
}

// support classes

.DisplayNone {
	display: none !important;
}
