@import "./variables";

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
/* Nunito Sams ( Extra Light, Light , Regular , Semi Bold, Bold) */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700&display=swap");

@font-face {
	font-family: "Century Gothic";
	font-weight: normal;
	src: url("./assets/fonts/century_gothic/century-gothic.ttf");
}

@font-face {
	font-family: "Century Gothic";
	font-weight: bold;
	src: url("./assets/fonts/century_gothic/century-gothic-bold.ttf");
}

* {
	margin: 0;
	padding: 0;

	font-family: "Nunito Sans";
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 14px;
}

#root {
	height: 100%;
}

.itemsContainer {
	width: 95%;
	margin: 0 auto;
	max-width: 1198px;

	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		color: #222222;
	}
}

html {
	scroll-behavior: smooth;
}

html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
}

.buttonBoxShadow {
	box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.08);
}

p {
	margin-top: 5px;
	font-size: 14px !important;
}
p.subtitle {
	font-weight: 600;
	font-size: 12px;
	color: #9b9b9b;
}

button {
	cursor: pointer;
}

#__react-alert__ {
	& > * {
		z-index: 999 !important;
	}
}
