#registerPage {
	.formRegisterContainer {
		margin: 0 auto;
		width: 420px;
		height: 600px;

		.formRegisterContent {
			.header {
				text-align: center;
				margin-bottom: 20px;
			}
		}

		.inputContainer {
			margin-bottom: 20px;
		}
	}
}
