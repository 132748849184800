.modalFormAddNewAddressContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;

	.formAddNewAddressContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px;

		width: 500px;
		height: auto;

		background-color: #ffffff;
		box-sizing: border-box;
		border-radius: 8px;

		padding: 30px;

		position: relative;

		.formNewAddressContent {
			width: 100%;
			height: 100%;

			position: relative;
			box-sizing: border-box;
			.inputContainer:not(:first-of-type) {
				margin: unset;
			}
			.closeModalIcon {
				position: absolute;
				z-index: 99;
				right: 0;
				top: 0px;
				cursor: pointer;
			}
			.formDataContainer,
			.illustrationAndMapContainer {
				width: 100%;
				height: 40%;
			}

			.illustrationAndMapContainer {
				display: flex;
				justify-content: center;
			}
			.formDataContainer {
				width: 100%;
				height: 55%;
				position: relative;

				padding-top: 15px;

				.titleBeforeSearchContainer {
					flex-direction: column;
					align-items: center;
				}

				.titleAfterSearchContainer {
					align-items: flex-start;
					.subtitle {
						margin-top: 5px !important;
					}

					animation: afterSearchAnimation 0.8s;

					@keyframes afterSearchAnimation {
						0% {
							opacity: 0;
						}
						100% {
							opacity: 1;
						}
					}
				}

				.titleBeforeSearchContainer,
				.titleAfterSearchContainer {
					display: flex;
					flex-direction: column;
					.title {
						font-weight: 600;
						font-size: 24px;
						color: #222222;
						text-align: center;
						line-height: 120%;
					}
					.subtitle {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						color: #9b9b9b;
						text-align: center;
						margin-top: 10px;
					}
				}

				.inputBeforeSearchContainer {
					display: flex;
					flex-direction: column;
					margin: 20px 0;
					justify-content: space-around;

					#districts {
						margin-top: 5px;
					}

					.currentLocalizationContainer {
						width: 100%;
						height: 48px;
						border: 1px solid #e8e9eb;
						border-radius: 4px;

						display: flex;
						align-items: center;
						justify-content: center;

						cursor: pointer;
						&:active {
							-webkit-box-shadow: inset 1px 1px 10px rgb(219, 219, 219);
							-moz-box-shadow: inset 1px 1px 10px rgb(219, 219, 219);
							box-shadow: inset 1px 1px 10px rgb(219, 219, 219);
						}
						svg {
							margin-right: 10px;
						}
					}
				}

				.inputAfterSearchContainer {
					padding-top: 5px;
					display: grid;
					row-gap: 5px;

					margin-bottom: 5px;

					.numberAndComplementContainer {
						display: grid;
						grid-template-columns: 7fr 2fr;
						column-gap: 5px;
						#siteNumber,
						#siteAddress {
							width: 100%;
						}
					}

					.districtCityAndStateContainer {
						#siteDistrict.inputContainer {
							width: 60%;
							margin-right: 5px;
						}
						#siteState.inputContainer {
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 700px) {
	#modalContainer.opened.modalFormAddNewAddress {
		display: block !important;
		.modalFormAddNewAddressContainer {
			.formAddNewAddressContainer {
				width: 100% !important;
				height: 95% !important;

				border-radius: 12px 12px 0 0 !important;

				animation: openInMobile 0.8s forwards;

				@keyframes openInMobile {
					0% {
						transform: translateY(100%);
					}
					100% {
						transform: translateY(3%);
					}
				}
			}
		}
	}
}
